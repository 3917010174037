import React from 'react'
import Column from '../../Components/Column'

const index = () => {
  return (
    <div>
        <Column />
    </div>
  )
}

export default index