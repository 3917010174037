export const tasks= [
    {"id": 1,
     "task": "Start Project"
    },
    {"id": 2,
     "task": "Create Mockups"
    },
    {"id": 3,
     "task": "Develop Frontend"
    },
    {"id": 4,
     "task": "Develop Backend"
    },
    {"id": 5,
     "task": "Testing"
    },
    {"id": 6,
     "task": "Deployment"
    }

]